<template>
  <div ref="station-info" class="station-info" v-if="currentStation">
    <h2 class="station-info__title" v-html="currentStation?.title"></h2>
    <div
      class="h4 station-info__description"
      :class="{
        'station-info__description--red':
          currentStation.station_id === 'Coillager',
      }"
      v-html="currentStation?.description"
    ></div>
    <template v-if="currentStation?.tabs">
      <div class="station-info__tabs">
        <button
          v-for="(tab, tabIndex) in currentStation.tabs"
          :key="tabIndex"
          class="station-info__tab"
          :class="{ 'station-info__tab--active': tabIndex === currentTabIndex }"
          @click="handleTabClick(tabIndex)"
        >
          {{ tabName(tab.type, tab.sub_type, tab.tab_title) }}
          <!-- {{ translations[tab.type === "text" ? "description" : tab.type] }} -->
        </button>
      </div>
      <div v-if="currentTabInfo.type === 'text'" class="station-info__texts">
        <template
          v-for="(paragraph, pIndex) in currentTabContent"
          :key="pIndex"
        >
          <ul
            class="station-info__texts__text"
            v-if="paragraph"
            v-html="paragraph"
          ></ul>
        </template>
      </div>
      <div v-if="currentTabInfo.type === 'images'" class="station-info__images">
        <div
          v-for="(image, imageIndex) in currentTabInfo.images"
          :key="imageIndex"
        >
          <img
            :alt="image.alt"
            :src="image.sizes.medium"
            @click="showImagePopover(image)"
          />
          <div
            class="station-info__texts__text station-info__texts__text--small"
            v-if="image.description"
            v-html="image.description"
          ></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {
      currentTabIndex: 0,
    }
  },
  computed: {
    ...mapGetters(["translations", "currentStation"]),
    currentTabInfo() {
      return this.currentStation.tabs[this.currentTabIndex]
    },
    currentTabContent() {
      return this.currentTabInfo.content
        .replaceAll("<ul>\n", "")
        .replaceAll("</ul>\n", "")
        .replaceAll("<p>&nbsp;</p>", '<p class="empty">&nbsp;</p>')
        .split("\n")
    },
  },
  watch: {
    currentStation() {
      this.currentTabIndex = 0
      if (this.$refs["station-info"]) {
        this.$refs["station-info"].scrollTop = 0
      }
    },
  },
  methods: {
    handleTabClick(tabIndex) {
      this.currentTabIndex = tabIndex
    },
    showImagePopover(image) {
      this.$store.commit("setCurrentImage", image)
    },
    tabName(type, subtype, tabTitle) {
      if (type === "text" && subtype === "description")
        return this.translations["description"]
      if (type === "text" && subtype === "facts")
        return this.translations["facts"]
      if (type === "text" && subtype === "custom")
        return this.translations["custom"] || tabTitle
      if (type === "images") return this.translations["images"]
    },
  },
}
</script>

<style lang="scss">
.station-info {
  overflow-y: auto;
  padding: 0 90px;

  @include mq($until: mobile-landscape) {
    padding: 0 $spacing-05;
  }

  &__title {
    font-weight: $font-weight-bold;
    margin-bottom: 20px;

    @include mq($until: mobile-landscape) {
      margin-bottom: $spacing-04;
    }
  }

  &__description {
    margin-bottom: 20px;

    &--red {
      color: $color-red-1;
    }
  }

  &__tabs {
    border-bottom: 1px solid $color-obsidian-1-25;
    color: $color-obsidian-5;
    display: flex;
    column-gap: $spacing-06;
    margin-bottom: 38px;
  }

  &__tab {
    background-color: initial;
    border: none;
    border-bottom: 3px solid transparent;
    transition: 300ms border $ease-out;
    cursor: pointer;
    padding: 20px 0;

    &--active {
      border-bottom: 3px solid $color-red-1;
      color: $color-red-1;
    }
  }

  &__texts {
    ul {
      border-bottom: 1px solid $color-obsidian-1-25;
      font-size: 18px;
      list-style-position: outside;
      padding: 12px 0;

      li {
        margin-left: 1rem;
        padding-left: 0.5rem;
      }
    }

    @supports selector(:has(*)) {
      ul:has(.empty) {
        border-bottom: none;
      }
    }

    &__text {
      &--small {
        font-size: 14px;
      }
    }
  }

  &__images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 38px;

    @include mq($from: mobile, $until: tablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px;
    }

    @include mq($until: mobile) {
      grid-template-columns: 1fr;
    }

    img {
      cursor: pointer;
      width: 100%;
    }
  }
}
</style>
