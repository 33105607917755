<template>
  <div class="map" ref="map" v-html="mapSvgData"></div>
  <div class="map__popover" v-show="showPopover">
    <div class="map__popover__index">0</div>
    <p class="map__popover__name">Dummy</p>
    <div class="map__popover__button" @click="goToStation">
      <BaseIcon>
        <IconChevronRightLight />
      </BaseIcon>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import panzoom from "panzoom"
import BaseIcon from "@/components/BaseIcon.vue"
import IconChevronRightLight from "@/components/icons/IconChevronRightLight.vue"

export default {
  components: {
    BaseIcon,
    IconChevronRightLight,
  },
  scales: {
    mapMinimized: 2.5,
    mapMaximized: 2,
  },
  data() {
    return {
      svg: null,
      panzoomInstance: null,
      scale: 1,
      showPopover: false,
      popoverAnimationFrame: null,
      popoverStationIndex: null,
      popoverMoved: false,
      startX: null,
      startY: null,
    }
  },
  mounted() {
    this.svg = this.$refs.map.getElementsByTagName("svg")[0]
    if (this.svg) {
      this.populateStationIndexes()
      this.showSelectedRoute()
      this.initializePanzoom()
      this.addEventListeners()
      this.updateScale()
      this.zoomToScale()
      this.animateToStation()
      this.highlightCurrentStation()
    } else {
      console.error("SVG element not found!")
    }
  },
  beforeUnmount() {
    this.removeEventListeners()
    this.dehighlightLastStation()
    cancelAnimationFrame(this.popoverAnimationFrame)
  },
  computed: {
    ...mapGetters([
      "mapSvgData",
      "tourData",
      "stationsData",
      "mapPathAndStationId",
      "currentStation",
      "isMapVisible",
      "isMapMinimized",
      "isCigaretteActive",
      "isWCActive",
      "isDefibrillatorActive",
      "windowHeight",
    ]),
    isMapFullheight() {
      return this.isMapVisible && !this.isMapMinimized
    },
    mapDimensions() {
      if (!this.svg) return {};
      const mapViewboxHeight = this.svg.viewBox.baseVal.height
      const mapViewboxWidth = this.svg.viewBox.baseVal.width
      const aspectRatio = mapViewboxHeight / mapViewboxWidth

      const mapHeight = this.windowHeight - 70 - 70
      const mapWidth = mapHeight / aspectRatio

      return {
        mapViewboxHeight,
        mapViewboxWidth,
        mapHeight,
        mapWidth,
      }
    },
    stationsRouteElement() {
      return this.svg?.getElementById(this.mapPathAndStationId?.station_id)
    },
    popoverStationElement() {
      return this.stationsRouteElement?.querySelector(
        `[id^="${this.stationsData[this.popoverStationIndex].station_id}"]`
      )
    },
  },
  watch: {
    isMapFullheight() {
      this.panzoomInstance.resume()
      // if (this.isMapFullheight) {
      //   this.panzoomInstance.resume()
      // } else {
      //   // pause if map not visible or minimized
      //   this.panzoomInstance.pause()
      // }
    },
    isMapMinimized() {
      this.updateScale()
      this.zoomToScale()
      this.animateToStation()
    },
    currentStation(newStation, previousStation) {
      this.animateToStation()
      this.dehighlightPreviousStation(previousStation)
      this.highlightCurrentStation()
    },
    isCigaretteActive() {
      const cigarettes = this.svg.querySelector("#Raucherpoints")
      if (cigarettes) {
        this.isCigaretteActive
          ? (cigarettes.style.display = "block")
          : (cigarettes.style.display = "none")
      }
    },
    isDefibrillatorActive() {
      const defibrillators = this.svg.querySelector("#Defis")
      if (defibrillators) {
        this.isDefibrillatorActive
          ? (defibrillators.style.display = "block")
          : (defibrillators.style.display = "none")
      }
    },
    isWCActive() {
      const wc = this.svg.querySelector("#Toiletten")
      if (wc) {
        this.isWCActive
          ? (wc.style.display = "block")
          : (wc.style.display = "none")
      }
    },
  },
  methods: {
    populateStationIndexes() {
      if (!this.stationsRouteElement) {
        console.error("Stations route element not found!")
        return;
      }

      this.stationsData.forEach((station, index) => {
        const stationGroupElement = this.stationsRouteElement.querySelector(
          `[id^="${station.station_id}"]`
        )

        if (!stationGroupElement) {
          console.error(`Station group element not found for station id: ${station.station_id}`)
          return;
        }

        try {
          const stationGroupElementInfo = stationGroupElement.getBBox()
          const stationTextElement =
            stationGroupElement.getElementsByTagName("text")[0]

          if (stationTextElement) {
            stationTextElement.setAttribute(
              "transform",
              `translate(${
                stationGroupElementInfo.x + stationGroupElementInfo.width / 2 + 1
              },${stationGroupElementInfo.y + stationGroupElementInfo.height / 2})`
            )
            stationTextElement.textContent = index + 1
          } else {
            console.error(`Text element not found in station group for station id: ${station.station_id}`)
          }
        } catch (error) {
          console.error(`Error processing station id: ${station.station_id}`, error)
        }
      })
    },
    showSelectedRoute() {
      if (!this.svg) return;
      this.svg.querySelectorAll("[id^='Route']").forEach((route) => {
        route.style.display = "none"
      })

      const pathElement = this.svg.getElementById(this.mapPathAndStationId.path_id)
      const stationElement = this.svg.getElementById(this.mapPathAndStationId.station_id)

      if (pathElement) pathElement.style.display = "block"
      if (stationElement) stationElement.style.display = "block"
    },
    initializePanzoom() {
      if (!this.svg) return;
      this.panzoomInstance = panzoom(this.svg, {
        minZoom: 0.8,
        maxZoom: Math.max(
          this.$options.scales.mapMinimized,
          this.$options.scales.mapMaximized
        ),
      })

      // if (!this.isMapFullheight) this.panzoomInstance.pause()

      this.panzoomInstance.on("zoom", () => {
        if (this.showPopover) this.showPopover = false
      })
    },
    addEventListeners() {
      window.addEventListener("resize", this.handleWindowResize)
      window.addEventListener("click", this.clickOutside)
      window.addEventListener("touchstart", this.clickOutside)

      this.stationsData.forEach((station, index) => {
        const stationElement = this.stationsRouteElement?.querySelector(
          `[id^="${station.station_id}"]`
        )
        if (stationElement) {
          stationElement.style.cursor = "pointer"
          stationElement.addEventListener("click", (e) =>
            this.showStationPopover(e, index)
          )
          stationElement.addEventListener("touchstart", (e) =>
            this.showStationPopover(e, index)
          )
        }
      })

      if (this.svg) {
        this.svg.addEventListener("mousedown", this.registerMousedown)
        this.svg.addEventListener("mouseup", this.registerMouseup)
      }
    },
    removeEventListeners() {
      window.removeEventListener("resize", this.handleWindowResize)
      window.removeEventListener("click", this.clickOutside)
      window.removeEventListener("touchstart", this.clickOutside)
      if (this.svg) {
        this.svg.removeEventListener("mousedown", this.registerMousedown)
        this.svg.removeEventListener("mouseup", this.registerMouseup)
      }
    },
    showStationPopover(e, index) {
      e.stopPropagation()

      this.showPopover = true
      this.popoverStationIndex = index

      const popoverElement = document.querySelector(".map__popover")
      if (popoverElement) {
        popoverElement.querySelector(".map__popover__index").textContent =
          index + 1
        popoverElement.querySelector(".map__popover__name").textContent =
          this.decodeHtml(this.stationsData[index].title)
      }

      this.updateStationPopover()
    },
    updateStationPopover() {
      this.popoverAnimationFrame = requestAnimationFrame(() => {
        this.updateStationPopover()
      })

      const panzoomData = this.panzoomInstance.getTransform()
      const stationInfo = this.popoverStationElement?.getBBox()
      if (stationInfo && this.mapDimensions.mapViewboxWidth && this.mapDimensions.mapViewboxHeight) {
        const stationX =
          (stationInfo.x / this.mapDimensions.mapViewboxWidth) *
          this.mapDimensions.mapWidth *
          panzoomData.scale
        const stationY =
          (stationInfo.y / this.mapDimensions.mapViewboxHeight) *
          this.mapDimensions.mapHeight *
          panzoomData.scale

        const popoverElement = document.querySelector(".map__popover")
        if (popoverElement) {
          // popoverElement.style.top = stationY + panzoomData.y + 50 + "px"
          // popoverElement.style.left = stationX + panzoomData.x - 25 + "px"
          popoverElement.style.top = stationY + panzoomData.y + 40 + "px"
          popoverElement.style.left = stationX + panzoomData.x - 30 + "px"
        }
      }
    },
    updateScale() {
      if (this.isMapMinimized) {
        this.scale = this.$options.scales.mapMinimized
      } else {
        this.scale = this.$options.scales.mapMaximized
      }
    },
    zoomToScale() {
      const scale = this.panzoomInstance.getTransform().scale
      if (scale !== this.scale) this.panzoomInstance.zoomAbs(0, 0, this.scale)
    },
    animateToStation() {
      const screenHeight = this.svg?.parentElement.clientHeight
      const screenWidth = this.svg?.parentElement.clientWidth

      if (!this.currentStation || !screenHeight || !screenWidth) return;

      const stationElement = this.stationsRouteElement.querySelector(
        `[id^="${this.currentStation.station_id}"]`
      )
      const stationInfo = stationElement?.getBBox()
      if (!stationInfo) return;
      const stationViewBoxX = stationInfo.x + 54 / 2
      const stationViewBoxY = stationInfo.y + 54 / 2

      const stationX =
        (stationViewBoxX / this.mapDimensions.mapViewboxWidth) *
        this.mapDimensions.mapWidth *
        this.scale
      const stationY =
        (stationViewBoxY / this.mapDimensions.mapViewboxHeight) *
        this.mapDimensions.mapHeight *
        this.scale

      const finalX = -stationX + screenWidth / 2
      const finalY = -stationY + screenHeight / 2

      if (this.svg) {
        this.svg.style.transition = "transform 0.4s"
        this.panzoomInstance.moveTo(finalX, finalY)
        setTimeout(() => {
          if (this.svg) this.svg.style.transition = "none"
        }, 400)
      }
    },
    highlightCurrentStation() {
      const currentStationElement = this.stationsRouteElement.querySelector(`[id^="${this.currentStation.station_id}"]`);
      if (currentStationElement) {
        currentStationElement.classList.add("active-index");
      }
      const currentBuildingElement = this.svg.getElementById(this.currentStation.building_id);
      if (currentBuildingElement) {
        currentBuildingElement.classList.add("active-building");
      }
    },
    dehighlightPreviousStation(previousStation) {
      const previousStationElement = this.stationsRouteElement.querySelector(`[id^="${previousStation.station_id}"]`);
      if (previousStationElement) {
        previousStationElement.classList.remove("active-index");
      }
      const previousBuildingElement = this.svg.getElementById(previousStation.building_id);
      if (previousBuildingElement) {
        previousBuildingElement.classList.remove("active-building");
      }
    },
    dehighlightLastStation() {
      const activeBuildingElement = this.svg.querySelector('.active-building');
      if (activeBuildingElement) {
        activeBuildingElement.classList.remove("active-building");
      }
      const activeIndexElement = this.svg.querySelector('.active-index');
      if (activeIndexElement) {
        activeIndexElement.classList.remove("active-index");
      }
    },
    goToStation(e) {
      e.preventDefault()
      e.stopPropagation()

      this.showPopover = false
      this.$store.commit("setIsMapMinimized", true)
      this.$store.commit("setIsContents", false)
      this.$router.push({
        name: "Tour",
        params: {
          tour: this.$route.params.tour,
          station:
            this.stationsData[
              this.popoverStationIndex
            ].station_id.toLowerCase(),
        },
      })
    },
    clickOutside(e) {
      const popoverElement = document.querySelector(".map__popover")
      if (popoverElement && !popoverElement.contains(e.target) && !this.popoverMoved) {
        this.showPopover = false
      }
    },
    registerMousedown(e) {
      this.startX = e.pageX
      this.startY = e.pageY

      this.popoverMoved = false
    },
    registerMouseup(e) {
      const endX = e.pageX
      const endY = e.pageY

      if (this.startX - endX || this.startY - endY) {
        this.popoverMoved = true
      }
    },
    decodeHtml(html) {
      const text = document.createElement("textarea")
      text.innerHTML = html
      return text.value
    },
    handleWindowResize() {
      this.animateToStation()
    },
  },
}
</script>

<style lang="scss">
.map {
  height: 100%;
  width: 100%;
  position: relative;

  > svg {
    display: block;
    height: calc($fullheight - 70px - 70px);
    width: auto;

    #Betriebsgelände {
      #Werksplan_SVG #Helper {
        display: none;
      }

      > g:not(#Werksplan_SVG) {
        display: none;
      }

      #Raucherpoints,
      #Toiletten {
        circle {
          r: 16px;
        }

        path {
          transform-box: fill-box;
          transform-origin: center;
          transform: scale(0.6);
        }
      }
      #Defis {
        g {
          transform-box: fill-box;
          transform-origin: center;
          transform: scale(0.6);
        }
      }
    }

    text {
      text-anchor: middle;
      dominant-baseline: central;
    }

    .active-index {
      pointer-events: none;

      rect {
        fill: $color-red-1;
        transform-box: fill-box;
        transform-origin: center;
        transform: scale(1.8);
      }

      text {
        font-size: 27px;
      }
    }

    .active-building {
      * {
        fill: #fceff2;
        stroke: $color-negative-outline;
      }
    }
  }

  &__popover {
    position: absolute;
    display: flex;
    align-items: center;
    column-gap: 10px;
    backface-visibility: hidden;
    background-color: $color-white;
    border-radius: $border-radius-xl;
    box-shadow: $box-shadow-popover;
    width: fit-content;
    padding: 20px;

    &__index {
      @include flex-center;
      flex-shrink: 0;
      background-color: $color-red-1;
      border-radius: $border-radius-m;
      color: $color-text-white;
      font-weight: $font-weight-bold;
      height: 30px;
      width: 30px;
    }

    &__button {
      @include flex-center;
      background-color: $color-obsidian-1-10;
      border-radius: $border-radius-m;
      cursor: pointer;
      margin-left: auto;
      margin-right: initial;
      padding: 3px;
    }
  }
}
</style>
